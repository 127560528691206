<template>
   <v-container fluid>
      <modal-eliminar :objModalEliminar="objModalEliminar"></modal-eliminar>
      <modal-habilitar :objModalHabilitar="objModalHabilitar"></modal-habilitar>
      <modal-inhabilitar :objModalInhabilitar="objModalInhabilitar"></modal-inhabilitar>
      <v-row>
         <v-col cols="12">
            <v-card class elevation max-width>
               <v-card-title class="justify-center">GESTIONAR PRODUCTOS</v-card-title>
               <v-divider></v-divider>
               <v-card-text>
                  <v-row>
                     <!-- Navegación -->
                     <div class="col-12">
                        <p>
                           <router-link
                              exact
                              style="text-decoration: none"
                              :to="{ name: 'inicioPanel' }"
                           >Inicio</router-link>/
                           <router-link
                              exact
                              style="text-decoration: none"
                              :to="{ name: 'productosPanel' }"
                           >Productos</router-link>
                        </p>
                     </div>
                     <!-- Navegación -->

                     <!-- Botones -->
                     <v-col cols="12" class="text-right">
                        <v-btn
                           v-show="validarPermiso('productos.crear')"
                           :to="{ name: 'productosCrearPanel' }"
                           color="primary"
                           dark
                           large
                        >
                           <v-icon left large>mdi-plus-circle</v-icon>NUEVO
                           PRODUCTO
                        </v-btn>
                     </v-col>

                     <v-col cols="12">
                        <v-btn
                           small
                           title="Limpiar filtros"
                           fab
                           dark
                           color="blue-grey"
                           depressed
                           @click="limpiarFiltros()"
                        >
                           <v-icon dark>mdi-refresh</v-icon>
                        </v-btn>
                     </v-col>
                     <!-- Botones -->

                     <!-- Filtros -->
                     <v-col xl="2" lg="2" md="2" sm="12" cols="12">
                        <v-select
                           v-model="cmbCantidadRegistros"
                           item-text="texto"
                           item-value="valor"
                           :items="arrCantidadRegistros"
                           label="Cantidad de registros"
                           @change="
                              paginaActual = 1;
                              listarRegistros();
                           "
                           dense
                           outlined
                        ></v-select>
                     </v-col>
                     <v-col xl="2" lg="2" md="2" sm="12" cols="12">
                        <v-select
                           @change="
                              paginaActual = 1;
                              listarRegistros();
                           "
                           v-model="filtroEstado"
                           clearable
                           dense
                           item-text="texto"
                           item-value="valor"
                           :items="cmbEstado"
                           label="Estado"
                           outlined
                        ></v-select>
                     </v-col>
                     <v-col xl="2" lg="2" md="2" sm="12" cols="12">
                        <v-select
                           @change="
                              paginaActual = 1;
                              listarRegistros();
                           "
                           v-model="filtroGenero"
                           clearable
                           dense
                           item-text="nombre"
                           item-value="idgenero"
                           :items="cmbGenero"
                           label="Generos"
                           outlined
                        ></v-select>
                     </v-col>
                     <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                        <v-select
                           @change="
                              paginaActual = 1;
                              listarRegistros();
                           "
                           v-model="filtroClase"
                           clearable
                           dense
                           item-text="nombre"
                           item-value="idmodelo_producto"
                           :items="cmbClase"
                           label="Clase"
                           outlined
                        ></v-select>
                     </v-col>
                     <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                        <v-select
                           @change="
                              paginaActual = 1;
                              listarRegistros();
                           "
                           v-model="filtroMarco"
                           clearable
                           dense
                           item-text="nombre"
                           item-value="idmarco"
                           :items="cmbMarco"
                           label="Marco"
                           outlined
                        ></v-select>
                     </v-col>
                     <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                        <v-text-field
                           @keyup.prevent.enter="
                              paginaActual = 1;
                              listarRegistros();
                           "
                           @click:clear="
                              paginaActual = 1;
                              filtroCodigo = '';
                              listarRegistros();
                           "
                           @keyup.prevent.delete="
                              paginaActual = 1;
                              listarRegistros();
                           "
                           v-model="filtroCodigo"
                           clearable
                           autocomplete="off"
                           outlined
                           dense
                           label="Codigo..."
                        ></v-text-field>
                     </v-col>
                     <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                        <v-text-field
                           @keyup.prevent.enter="
                              paginaActual = 1;
                              listarRegistros();
                           "
                           @click:clear="
                              paginaActual = 1;
                              filtroNombre = '';
                              listarRegistros();
                           "
                           @keyup.prevent.delete="
                              paginaActual = 1;
                              listarRegistros();
                           "
                           v-model="filtroNombre"
                           clearable
                           autocomplete="off"
                           outlined
                           dense
                           label="Nombre..."
                        ></v-text-field>
                     </v-col>
                     <v-col xl="3" lg="3" md="3" sm="12" cols="12">
                        <v-text-field
                           @keyup.prevent.enter="
                              paginaActual = 1;
                              listarRegistros();
                           "
                           @click:clear="
                              paginaActual = 1;
                              filtroInventario = '';
                              listarRegistros();
                           "
                           @keyup.prevent.delete="
                              paginaActual = 1;
                              listarRegistros();
                           "
                           v-model="filtroInventario"
                           clearable
                           autocomplete="off"
                           outlined
                           dense
                           label="Inventario..."
                        ></v-text-field>
                     </v-col>
                     <v-col xl="3" lg="3" md="3" sm="12" cols="12" class="text-center">
                        <v-btn class="my-0" color="info" elevation @click="filtrar()">
                           <v-icon>mdi-filter</v-icon>Filtrar
                        </v-btn>
                     </v-col>
                     <!-- Filtros -->

                     <!-- botones extras -->
                     <v-col cols="12">
                        <v-btn :loading="btnReporteExcel" fab elevation @click="reporteExcel()">
                           <v-icon large color="success">mdi-file-excel</v-icon>
                        </v-btn>
                     </v-col>
                     <!-- botones extras -->

                     <!-- Tabla -->
                     <v-col cols="12" v-show="arrRegistrosTabla.length > 0">
                        <v-simple-table dense fixed-header ref="tblListado">
                           <template v-slot:default>
                              <thead class="text-no-wrap">
                                 <tr>
                                    <th class="text-center">
                                       <p>ACCIONES</p>
                                    </th>
                                    <th class="text-center">
                                       <p>ESTADO</p>
                                    </th>
                                    <th class="text-center">
                                       <p class="margin-cabecera-tabla">CODIGO</p>
                                    </th>
                                    <th class="text-center">
                                       <p class="margin-cabecera-tabla">NOMBRE</p>
                                    </th>
                                    <th class="text-center">
                                       <p class="margin-cabecera-tabla">INVENTARIO</p>
                                    </th>
                                    <th class="text-center">
                                       <p>PRECIO</p>
                                    </th>
                                    <th class="text-center">
                                       <p>PRECIO LISTA</p>
                                    </th>
                                    <th class="text-center">
                                       <p>CLASES</p>
                                    </th>
                                    <th class="text-center">
                                       <p class="margin-cabecera-tabla">MARCO</p>
                                    </th>
                                    <th class="text-center">
                                       <p class="margin-cabecera-tabla">Genero</p>
                                    </th>
                                    <th class="text-center">
                                       <p>FLEX</p>
                                    </th>
                                    <th class="text-center">
                                       <p>NUEVO</p>
                                    </th>
                                    <th class="text-center">
                                       <p>MAS VENDIDO</p>
                                    </th>
                                    <th class="text-center">
                                       <p>PUENTE</p>
                                    </th>
                                    <th class="text-center">
                                       <p>LUNA</p>
                                    </th>
                                    <th class="text-center">
                                       <p>ANCHO</p>
                                    </th>
                                    <th class="text-center">
                                       <p>LARGO</p>
                                    </th>
                                    <th class="text-center">
                                       <p>ALTO</p>
                                    </th>
                                    <th class="text-center">
                                       <p>MATERIAL</p>
                                    </th>
                                 </tr>
                              </thead>
                              <tbody class="text-no-wrap">
                                 <tr v-for="item in arrRegistrosTabla" :key="item.idproducto">
                                    <td>
                                       <v-menu offset-y>
                                          <template v-slot:activator="{ on, attrs }">
                                             <v-btn
                                                small
                                                dark
                                                depressed
                                                color="cyan"
                                                v-bind="attrs"
                                                v-on="on"
                                             >
                                                <v-icon left>mdi-cursor-default-click</v-icon>Acciones
                                             </v-btn>
                                          </template>
                                          <v-list>
                                             <v-list-item 
                                                v-show="item.idtipo_producto == TIPO_PRODUCTO.MONTURA" 
                                                v-for="clase in item.clases" 
                                                :key="clase.idmodelo_producto" 
                                                :href="linkDetalle(item,clase)" target="__blank"
                                             >
                                                <v-icon>mdi-eye</v-icon> Ver {{ clase.nombre }}
                                             </v-list-item>

                                             <v-list-item 
                                                v-show="item.idtipo_producto == TIPO_PRODUCTO.ACCESORIO" 
                                                :href="linkDetalle(item)" target="__blank"
                                             >
                                                <v-icon>mdi-eye</v-icon> Ver accesorio
                                             </v-list-item>
                                             


                                             <v-list-item v-show=" validarPermiso( 'productos.editar' ) "
                                                :to="{
                                                   name: 'productosEditarPanel',
                                                   params: { idproducto: item.idproducto, },
                                                }"
                                             >
                                                <v-list-item-title>
                                                   <v-icon>mdi-pencil</v-icon>Editar
                                                </v-list-item-title>
                                             </v-list-item>

                                             <v-list-item v-if="item.estado" v-show=" validarPermiso( 'productos.inhabilitar' ) "
                                                @click="
                                                   objModalInhabilitar = {
                                                      idproducto: item.idproducto,
                                                      openModal: true,
                                                   }
                                                "
                                             >
                                                <v-list-item-title>
                                                   <v-icon>mdi-close</v-icon>Inhabilitar
                                                </v-list-item-title>
                                             </v-list-item>

                                             <v-list-item v-else v-show=" validarPermiso( 'productos.habilitar' ) "
                                                @click="
                                                   objModalHabilitar = {
                                                      idproducto: item.idproducto,
                                                      openModal: true,
                                                   }
                                                "
                                             >
                                                <v-list-item-title>
                                                   <v-icon>mdi-check</v-icon>Habilitar
                                                </v-list-item-title>
                                             </v-list-item>

                                             <v-list-item v-show=" validarPermiso( 'productos.eliminar' ) "
                                                @click="
                                                   objModalEliminar = {
                                                      idproducto: item.idproducto,
                                                      openModal: true,
                                                   }
                                                "
                                             >
                                                <v-list-item-title>
                                                   <v-icon>mdi-trash-can</v-icon>Eliminar
                                                </v-list-item-title>
                                             </v-list-item>
                                          </v-list>
                                       </v-menu>
                                    </td>
                                    <td>
                                       <v-chip
                                          small
                                          class="ma-2"
                                          color="green"
                                          text-color="white"
                                          v-if="item.estado"
                                       >Habilitado</v-chip>
                                       <v-chip
                                          small
                                          class="ma-2"
                                          color="red"
                                          text-color="white"
                                          v-else
                                       >Inhabilitado</v-chip>
                                    </td>
                                    <td v-text="item.codigo"></td>
                                    <td v-text="item.nombre"></td>
                                    <td v-text="item.inventario"></td>
                                    <td v-text="item.precio"></td>
                                    <td v-text="item.precio_lista"></td>
                                    <td>
                                       <ul v-if="item.clases != null">
                                          <li
                                             v-for="clase in item.clases"
                                             :key="clase.idmodelo_producto"
                                             v-text="clase.nombre"
                                          ></li>
                                       </ul>
                                    </td>
                                    <td>
                                       <span v-if="item.marco != null" v-text="item.marco.nombre"></span>
                                    </td>
                                    <td>
                                       <ul v-if="item.generos != null">
                                          <li
                                             v-for="genero in item.generos"
                                             :key="genero.idgenero"
                                             v-text="genero.nombre"
                                          ></li>
                                       </ul>
                                    </td>
                                    <td v-text="item.flex ? 'SI' : 'NO'"></td>
                                    <td v-text="item.nuevo ? 'SI' : 'NO'"></td>
                                    <td v-text="item.mas_vendido ? 'SI' : 'NO'"></td>
                                    <td v-text="item.puente"></td>
                                    <td v-text="item.luna"></td>
                                    <td v-text="item.ancho"></td>
                                    <td v-text="item.largo"></td>
                                    <td v-text="item.alto"></td>
                                    <td>
                                       <ul>
                                          <li
                                             v-for="material in item.materiales"
                                             :key="material.idmaterial"
                                             v-text="material.nombre"
                                          ></li>
                                       </ul>
                                    </td>
                                 </tr>
                              </tbody>
                           </template>
                        </v-simple-table>

                        <p>
                           Mostrando del registro
                           <span v-text="primerRegistro"></span> al
                           <span v-text="ultimoRegistro"></span> de un total de
                           <span v-text="totalRegistro"></span> filas
                        </p>
                     </v-col>

                     <v-col cols="12 text-center" v-show="loaderListado">
                        <v-progress-circular indeterminate color="primary" :size="100" :width="7"></v-progress-circular>
                     </v-col>

                     <v-col cols="12" v-show="arrRegistrosTabla.length > 0">
                        <v-pagination
                           circle
                           v-model="paginaActual"
                           :length="cantidadproductos"
                           :total-visible="7"
                           @input="listarRegistros()"
                           :disabled="loaderListado"
                        ></v-pagination>
                     </v-col>
                     <!-- Tabla -->

                     <!-- En caso de no haber registros -->
                     <v-col cols="12" v-show="alertRegistroNoEcontrado">
                        <v-alert
                           type="error"
                           dense
                           outlined
                           class="text-center"
                        >NO HAY REGISTROS DISPONIBLES PARA MOSTRAR</v-alert>
                     </v-col>
                     <!-- En caso de no haber registros -->
                  </v-row>
               </v-card-text>
            </v-card>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
import modalEliminar from "./Eliminar";
import modalHabilitar from "./Habilitar";
import modalInhabilitar from "./Inhabilitar";

export default {
   components: {
      modalEliminar,
      modalHabilitar,
      modalInhabilitar,
   },
   data() {
      return {
         TIPO_PRODUCTO : {
            MONTURA : 1,
            ACCESORIO : 2,
         },
         arrCantidadRegistros: [
            {
               texto: 10,
               valor: 10,
            },
            {
               texto: 30,
               valor: 30,
            },
            {
               texto: 50,
               valor: 50,
            },
            {
               texto: 100,
               valor: 100,
            },
            {
               texto: 500,
               valor: 500,
            },
         ],
         cmbEstado: [
            {
               texto: "Habilitado",
               valor: 1,
            },
            {
               texto: "Inhabilitado",
               valor: 0,
            },
         ],
         cmbClase: [],
         cmbMarco: [],
         cmbGenero: [],
         loaderListado: false,
         alertRegistroNoEcontrado: false,
         cmbCantidadRegistros: 10,
         arrRegistrosTabla: [],
         paginaActual: 1,
         cantidadproductos: 1,
         primerRegistro: 0,
         ultimoRegistro: 0,
         totalRegistro: 0,
         filtroEstado: "",
         filtroCodigo: "",
         filtroNombre: "",
         filtroInventario: "",
         filtroClase: "",
         filtroMarco: "",
         filtroGenero: "",
         objModalEliminar: null,
         objModalHabilitar: null,
         objModalInhabilitar: null,
         btnReporteExcel: false,
      };
   },

   methods: {
      listarRegistros() {
         this.arrRegistrosTabla = [];
         this.loaderListado = true;
         this.alertRegistroNoEcontrado = false;
         this.axios({
            method: "GET",
            url: "api/panel/productos",
            params: {
               cantidadRegistros: this.cmbCantidadRegistros,
               paginaActual: this.paginaActual,
               filtroEstado: this.filtroEstado,
               filtroCodigo: this.filtroCodigo,
               filtroNombre: this.filtroNombre,
               filtroInventario: this.filtroInventario,
               filtroClase: this.filtroClase,
               filtroMarco: this.filtroMarco,
               filtroGenero: this.filtroGenero,
            },
         })
            .then((response) => {
               let listado = response.data.data;

               this.arrRegistrosTabla = listado.data;
               this.paginaActual = listado.current_page;
               this.cantidadproductos = listado.last_page;
               this.primerRegistro = listado.from;
               this.ultimoRegistro = listado.to;
               this.totalRegistro = listado.total;

               if (this.arrRegistrosTabla.length === 0) {
                  this.alertRegistroNoEcontrado = true;
               } else {
                  this.alertRegistroNoEcontrado = false;
               }
            })
            .catch((error) => {
               console.log(error.response);
            })
            .finally(() => {
               this.loaderListado = false;
               this.$vuetify.goTo(this.$refs.tblListado);
            });
      },

      getFiltros() {
         this.axios({
            method: "POST",
            url: "api/panel/productos/filtros",
         })
            .then((response) => {
               let status = response.status;
               if (status === 200) {
                  this.cmbClase = response.data.data.clases;
                  this.cmbMarco = response.data.data.marcos;
                  this.cmbGenero = response.data.data.generos;
               }
            })
            .catch((error) => {
               console.log(error);
            });
      },

      reporteExcel() {
         window.open(
            this.apiUrl(
               "api/panel/productos/reporteExcel?filtroEstado=" +
                  this.filtroEstado +
                  "&filtroCodigo=" +
                  this.filtroCodigo +
                  "&filtroNombre=" +
                  this.filtroNombre +
                  "&filtroInventario=" +
                  this.filtroInventario +
                  "&filtroClase=" +
                  this.filtroClase +
                  "&filtroMarco=" +
                  this.filtroMarco +
                  "&filtroGenero=" +
                  this.filtroGenero
            ),
            "_self"
         );

         /*this.btnReporteExcel = true;
            this.axios({
               method: "POST",
               url: "api/panel/productos/reporteExcel",
               responseType: "blob",
               data: {
                  filtroEstado: this.filtroEstado,
                  filtroCodigo: this.filtroCodigo,
                  filtroNombre: this.filtroNombre,
                  filtroInventario: this.filtroInventario,
                  filtroClase: this.filtroClase,
                  filtroMarco: this.filtroMarco,
               },
            })
               .then((response) => {
                  let status = response.status;
                  if (status === 200) {
                  let objUrl = URL.createObjectURL(response.data);
                  const link = document.createElement("a");
                  link.setAttribute("href", objUrl);
                  link.setAttribute(
                     "download",
                     "reporte_productos_" + this.$moment().format("DD-MM-YYYY_hh-mm-ss")
                  );
                  link.click();
                  }
               })
               .catch((error) => {
                  console.log(error);
               })
               .then(() => {
                  this.btnReporteExcel = false;
               });*/
      },

      filtrar() {
         this.paginaActual = 1;
         this.listarRegistros();
      },

      limpiarFiltros() {
         this.cmbCantidadRegistros = 10;
         this.paginaActual = 1;
         this.filtroEstado = "";
         this.filtroCodigo = "";
         this.filtroNombre = "";
         this.filtroInventario = "";
         this.filtroClase = "";
         this.filtroMarco = "";
         this.filtroGenero = "";
         this.listarRegistros();
      },
      linkDetalle(item,clase = {}) {
         const TIPO_PRODUCTO =  this.TIPO_PRODUCTO;

         const MODELO_PRODUCTO = {
            OPTICOS: 2,
            SOL: 3,
            DEPORTIVOS: 5,
         };



         if ( item.idtipo_producto == TIPO_PRODUCTO.MONTURA ) {
            if (clase.idmodelo_producto == MODELO_PRODUCTO.OPTICOS) {
               return process.env.VUE_APP_URL_WEB+'lentes-de-medida/'+item.idproducto+'/'+item.slug; 
            }
            
            if (clase.idmodelo_producto == MODELO_PRODUCTO.SOL) {
               return process.env.VUE_APP_URL_WEB+'lentes-de-sol-con-medida/'+item.idproducto+'/'+item.slug; 
            }
            
            if (clase.idmodelo_producto == MODELO_PRODUCTO.DEPORTIVOS) {
               return process.env.VUE_APP_URL_WEB+'lentes-deportivos/'+item.idproducto+'/'+item.slug; 
            }            
         }

         if ( item.idtipo_producto == TIPO_PRODUCTO.ACCESORIO ) {
            return process.env.VUE_APP_URL_WEB+'accesorios/'+item.idproducto+'/'+item.slug;
         }

         return 'javascript:void(0);';
      }
   },

   created() {
      if (!this.validarPermiso("productos.listado")) {
         this.$toasted.error("Usted no tiene permisos para esta sección", {
            icon: "mdi-close",
         });

         this.$router.push({
            name: "inicioPanel",
         });
      }
      this.listarRegistros();
      this.getFiltros();
   },
};
</script>

<style scoped>
.margin-cabecera-tabla {
   margin-right: 60px !important;
   margin-left: 60px !important;
}
</style>
